<template>
    <div class='bet_success'>
        <div class="bet_success_top">
            <img src="../assets/imgs/bet_success_red.png" alt="">
            <p>{{title}}</p>
        </div>
        <ul class="bet_success_handle">
            <li v-for="(item, index) in buttons" :key="index" @click="jumpEvent(item.eventName, item.route)">
                {{item.label}}
            </li>
        </ul>
        <div class="bet_success_tips">
            <h3>重要提示：</h3>
            <div v-for="(tip, index) in tips" :key="index" v-html="tip"></div>
        </div>
    </div>
</template>
<script lang='ts'>
import { reactive, toRefs } from 'vue'
import { getQueryStr, goBack, jumpTo } from '../utils/util'
export default {
    name: '',
    setup() {
         const state= reactive({
             buttons: [
                 {label: '继续选号', eventName: 'choose', route: '/choosenumber?gameId=11'},
                 {label: '个人中心', eventName: 'mine', route: '/mine'}
             ],
             title: '短信投注格式已生成！',
             tips: [
                 '1、发送短信后，如长时间未收到投注状态的短信回复，请联系客服：<a href="tel:021-6417-5077">021-6417-5077</a>(9:00-20:00);',
                 '2、23:00-0:50系统结算期间，短信投注将延迟处理，请勿重复投注。'
             ]
        })
        const id = getQueryStr('id')
        function initRoute() {
            if (id) {
                state.buttons[0].route = `/choosenumber?gameId=${id}`
            } 

        }
        function jumpEvent(eventName, path) {
            if (eventName == 'choose') {
                goBack(id ? -2 : -1)
            } else {
                jumpTo(path)
            }
        }
        initRoute()
        return {
            ...toRefs(state),
            goBack,
            jumpEvent
        }
    },
}
</script>
<style lang='scss' scoped>
.bet_success {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    &_top {
        text-align: center;
        padding-top: 60px;
        padding-bottom: 10px;
        font-weight: 700;
        font-size: $font-big;
        img {
            width: 75px;
        }
    }
    &_handle {
        display: flex;
        justify-content: space-between;
        color: #fff;
        padding: 20px 15px;
        li {
            width: 45%;
            text-align: center;
            font-size: $font-middle;
            border-radius: $box-radius-small;
            padding: 8px 0;
            &:first-child {
                background: $theme-dark;
            }
            &:last-child {
                background: $yellow-dark;
            }
        }
    }
    &_tips {
        font-size: $font-small;
        padding: 40px 15px 0 15px;
        h3 {
            padding-bottom: 5px;
        }
    }
}
</style>